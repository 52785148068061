/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { gql, useQuery } from "@apollo/client";
import { Link } from "gatsby";
import { MenuItem, Popover, Snackbar, Skeleton, Alert } from "@mui/material";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import PropTypes from "prop-types";
import {
  EventRounded,
  ImportExportRounded,
  WarningTwoTone,
} from "@mui/icons-material";
import { FcGoogle } from "react-icons/fc";
import { FiClipboard } from "react-icons/fi";
import { TiVendorApple, TiVendorMicrosoft } from "react-icons/ti";

import { PageTitleContext } from "../utils/pagetitle-provider";
import { rightNowInt } from "../utils/global-consts";
import {
  ContentWithoutUtilsBar,
  SupportTile,
  TilePrim,
  TimeLeftDiv,
} from "../components/util-components";
import { UserRolesContext } from "../utils/userroles-provider";
import { arrayCreator, formatDE } from "../utils/util-functions";
import JimHelmet from "../components/JimHelmet";

// "http://devjimbucket.s3.eu-central-1.amazonaws.com/calendar/user/eb9ce3cb-4216-42f8-922a-85adb677522d/calendar.ics"

const today = new Date(rightNowInt);
today.setHours(0, 0, 0, 0);

const vendorsObj = [
  {
    label: "Google",
    icon: <FcGoogle />,
    hrefPrefix: `https://www.google.com/calendar/render?cid=http`,
    target: "_blank",
  },
  {
    label: "Apple",
    icon: <TiVendorApple />,
    hrefPrefix: `webcal`,
    target: "_top",
  },
  {
    label: "Outlook",
    icon: <TiVendorMicrosoft />,
    hrefPrefix: `webcal`,
    target: "_top",
  },
  {
    label: "Outlook.com",
    icon: <TiVendorMicrosoft />,
    hrefPrefix: `http://calendar.live.com/calendar/calendar.aspx?rru=addsubscription&amp;amp;name=http`,
    target: "_blank",
  },
];

const USER_AVAILS_AND_DEPTS_QUERY = gql`
  query ListUserAvailsAndDepts($startsAfter: Float) {
    getUser {
      first_name
      last_name
      email
      phone
      availabilities(startsAfter: $startsAfter) {
        start_time
        end_time
      }
      accepted_jobs(startsAfter: $startsAfter) {
        start_time
        end_time
      }
    }
  }
`;

// const LIST_POOLS_QUERY = gql`
//   query ListPools($facility: [ID]) {
//     listPools(facility: $facility) {
//       title
//     }
//   }
// `

const DashboardLinkWrapper = tw(
  Link
)`flex flex-shrink-0 justify-center items-center self-start px-2 py-1 mt-2 rounded font-semibold tracking-wide text-white transition duration-200 bg-prim-500 hover:bg-prim-600`;
const DashboardLink = ({ to, label, ...rest }) => (
  <DashboardLinkWrapper to={to} {...rest}>
    {label}
  </DashboardLinkWrapper>
);
DashboardLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

// const DBJobTile = ({ title, data, path, userJob }) => (
//   <TilePrim
//     onClick={data && data !== "noJobData" ? () => navigate(path) : null}
//   >
//     <TileTitle>{title}</TileTitle>
//     <div tw="pt-2 space-y-2">
//       {data === "noJobData" && userJob && (
//         <>
//           <div tw="font-semibold text-lg">
//             Aktuell hast du keine ausstehenden Jobs
//           </div>
//           <div tw="flex items-center">
//             <TextButton onClick={() => navigate("/jobs/current")}>
//               Zu meinen Jobs
//             </TextButton>
//           </div>
//           <div tw="flex items-center">
//             <JimButton variant="primary" onClick={() => navigate("/jobs/search")}>
//               Jetzt nach Jobs suchen
//             </JimButton>
//           </div>
//         </>
//       )}
//       {data === "noJobData" && !userJob && (
//         <>
//           <div tw="font-semibold text-lg">
//             Aktuell haben wir leider keine Jobs für dich
//           </div>
//           <div tw="flex items-center">
//             Reiche weitere Erfahrungen ein, um mehr Jobangebote zu bekommen!
//           </div>
//           <div tw="flex items-center">
//             <JimButton
//               variant="primary"
//               onClick={() => navigate("/experiences")}
//             >
//               Jetzt Erfahrung einreichen
//             </JimButton>
//           </div>
//         </>
//       )}
//       {data !== "noJobData" && (
//         <>
//           <div tw="font-semibold text-lg">{data.job.title}</div>
//           <div tw="flex items-center">
//             <LocationOnTwoTone tw="mr-2 text-lg" />
//             {data.department.name}
//           </div>
//           <div tw="flex items-center">
//             <ScheduleTwoTone tw="mr-2 text-lg" />
//             {beautifyDateAndTime(data.start_time)}
//           </div>
//         </>
//       )}
//       {userJob && data !== "noJobData" && (
//         <>
//           <ArrowForwardRounded tw="mx-2 text-lg" />
//           <TimeLeftDiv start={data.start_time} />
//         </>
//       )}
//     </div>
//   </TilePrim>
// )

function Home() {
  const { roles, userCal } = useContext(UserRolesContext);
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Dashboard"), []);
  const { data: userData, error: userDataError } = useQuery(
    USER_AVAILS_AND_DEPTS_QUERY,
    {
      variables: { startsAfter: rightNowInt },
    }
  );
  // const { data: poolData } = useQuery(LIST_POOLS_QUERY, {
  //   variables: { facility: Object.keys(userFacilitiesByID) },
  // })
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverID = anchorEl ? "select-cal-vendor" : undefined;

  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    severity: "success",
    text: "Kalender-URL kopiert!",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowCopyAlert(false);
  };

  const isProfileComplete =
    !userData ||
    (userData &&
      userData.getUser.first_name !== "" &&
      userData.getUser.last_name !== "" &&
      userData.getUser.email !== "" &&
      userData.getUser.phone !== "");

  const availTimeString = (start, end) =>
    `${formatDE(new Date(start), "HH:mm")} - ${formatDE(
      new Date(end),
      "HH:mm"
    )}`;

  return (
    <>
      <JimHelmet path="" />
      <ContentWithoutUtilsBar>
        {!isProfileComplete && (
          <>
            <TilePrim tw="sm:flex-row divide-y-0 md:(items-center space-y-0) mb-6">
              <div tw="flex items-center space-x-4">
                <WarningTwoTone tw="text-xl text-yellow-500" />
                <div tw="flex">
                  Scheinbar fehlen uns noch ein paar Daten von dir.
                </div>
                <DashboardLink
                  to="/personal-data"
                  label="jetzt vervollständigen"
                  tw="mt-0"
                />
              </div>
            </TilePrim>
            <TilePrim tw="md:flex-row divide-y-0 md:(items-center space-y-0 space-x-6) border-2 border-blue-200">
              <div tw="flex">
                Du verwaltest eine Einrichtung und möchtest Funktionen für
                ArbeitgeberInnen nutzen?
              </div>
              <DashboardLink
                to="/create-facility"
                label="Los geht's!"
                tw="mt-0 bg-blue-500 hover:bg-blue-600"
              />
            </TilePrim>
          </>
        )}
        <div tw="grid gap-4 grid-cols-1 sm:grid-cols-2">
          {/* {roles.includes("EMPLOYER") && (
          <TilePrim tw="my-0" title="Pools anzeigen">
            {poolData && poolData.listPools[0] && (
              <>
                <ul tw="list-disc pl-6">
                  {arrayCreator(0, 2, 1).map(ind => (
                    <>
                      {poolData.listPools[ind] && (
                        <li key={`pool-${ind}`}>
                          {poolData.listPools[ind].title}
                        </li>
                      )}
                    </>
                  ))}
                  {poolData.listPools[3] && (
                    <li key={`pool-${3}`}>
                      {poolData.listPools[4]
                        ? "..."
                        : poolData.listPools[3].title}
                    </li>
                  )}
                </ul>
              </>
            )}
            {poolData && !poolData.listPools[0] && (
              <div>
                Scheinbar gibt es aktuell keine Pools, die von dir verwaltet
                werden.
              </div>
            )}
            {!poolData ? (
              <Skeleton width={100} />
            ) : (
              <DashboardLink
                to="/manage-facility/pools"
                label="zu meinen Pools"
              />
            )}
          </TilePrim>
        )} */}
          {roles.includes("WORKER") && (
            <>
              <TilePrim
                tw="my-0 text-sm sm:text-base"
                title="Deine nächsten Einsätze"
                btnLabel={
                  userCal ? (
                    <>
                      <ImportExportRounded tw="-mr-1 text-blue-500" />
                      <EventRounded tw="text-blue-500" />
                      <Popover
                        id={popoverID}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div tw="py-1 px-2 text-gray-500">
                          Kalender abonnieren:
                        </div>
                        {vendorsObj.map(vendor => (
                          <MenuItem>
                            {vendor.icon}
                            <a
                              tw="ml-2"
                              href={`${vendor.hrefPrefix}${userCal.replace(
                                "https",
                                ""
                              )}`}
                              target={vendor.target}
                              rel={
                                vendor.target === "_blank"
                                  ? "noreferrer"
                                  : undefined
                              }
                            >
                              {vendor.label}
                            </a>
                          </MenuItem>
                        ))}
                        <MenuItem>
                          <FiClipboard />
                          <div
                            tw="ml-2"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(userCal)
                                .then(() => {
                                  setAlertProps({
                                    severity: "success",
                                    text: "Kalender-URL kopiert!",
                                  });
                                })
                                .catch(() => {
                                  setAlertProps({
                                    severity: "error",
                                    text: "Kopieren fehlgeschlagen",
                                  });
                                })
                                .finally(() => {
                                  setShowCopyAlert(true);
                                });
                            }}
                          >
                            URL kopieren
                          </div>
                        </MenuItem>
                      </Popover>
                    </>
                  ) : undefined
                }
                btnHandler={e =>
                  userCal
                    ? setAnchorEl(anchorEl ? null : e.currentTarget)
                    : undefined
                }
              >
                {userData && userData.getUser.accepted_jobs[0] && (
                  <>
                    <div tw="flex flex-col space-y-2">
                      {arrayCreator(0, 2, 1).map(
                        ind =>
                          userData.getUser.accepted_jobs[ind] && (
                            <div
                              tw="flex space-x-4"
                              key={`accepted-job-${ind}`}
                            >
                              <div tw="font-semibold">
                                {format(
                                  new Date(
                                    userData.getUser.accepted_jobs[
                                      ind
                                    ].start_time
                                  ),
                                  "eee, dd.MM.yy",
                                  {
                                    locale: de,
                                  }
                                )}
                              </div>
                              <div>
                                {availTimeString(
                                  userData.getUser.accepted_jobs[ind]
                                    .start_time,
                                  userData.getUser.accepted_jobs[ind].end_time
                                )}
                              </div>
                              <div>
                                <TimeLeftDiv
                                  start={
                                    userData.getUser.accepted_jobs[ind]
                                      .start_time
                                  }
                                />
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <DashboardLink
                      to="/jobs/current"
                      label="zu meinen Einsätzen"
                    />
                  </>
                )}
                {userData && !userData.getUser.accepted_jobs[0] && (
                  <>
                    <div>Aktuell hast du keine anstehenden Einsätze.</div>
                    <div>
                      Du kannst weitere Verfügbarkeiten melden oder nach offenen
                      Einsätzen suchen.
                    </div>
                    <DashboardLink
                      to="/jobs/find"
                      label="Jetzt Einsätze suchen"
                    />
                  </>
                )}
                {!userData && <Skeleton width={100} />}
              </TilePrim>
              <TilePrim tw="my-0" title="Deine nächsten Verfügbarkeiten">
                {userData && userData.getUser.availabilities[0] && (
                  <>
                    <div tw="flex flex-col space-y-2">
                      {arrayCreator(0, 2, 1).map(
                        ind =>
                          userData.getUser.availabilities[ind] && (
                            <div tw="flex space-x-4" key={`avail-${ind}`}>
                              <div tw="font-semibold">
                                {format(
                                  new Date(
                                    userData.getUser.availabilities[
                                      ind
                                    ].start_time
                                  ),
                                  "eee, dd.MM.yy",
                                  {
                                    locale: de,
                                  }
                                )}
                              </div>
                              <div>
                                {availTimeString(
                                  userData.getUser.availabilities[ind]
                                    .start_time,
                                  userData.getUser.availabilities[ind].end_time
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <DashboardLink
                      to="/avails-user"
                      label="zu meinen Verfügbarkeiten"
                    />
                  </>
                )}
                {userData && !userData.getUser.availabilities[0] && (
                  <>
                    <div>Aktuell hast du keine Verfügbarkeiten gemeldet.</div>
                    <div>
                      Du möchstest in den kommenden Tagen gerne eingesetzt
                      werden und weißt schon, welche Tage dir besonders gut
                      passen?
                    </div>
                    <DashboardLink
                      to="/avails-user"
                      label="Jetzt Verfügbarkeit melden"
                    />
                  </>
                )}
                {!userData && <Skeleton width={100} />}
              </TilePrim>
            </>
          )}
        </div>
        <Snackbar
          open={showCopyAlert}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertProps.severity}
            elevation={6}
            variant="filled"
          >
            {alertProps.text}
          </Alert>
        </Snackbar>
        {userDataError && (
          <SupportTile text="Leider ist etwas in der Kommunikation mit unserem Server schief gelaufen. Bitte versuche es später erneut oder nimm Kontakt mit unserem Support auf." />
        )}
      </ContentWithoutUtilsBar>
    </>
  );
}

export default Home;
